import { defineStore } from "pinia"

export const useUserStore = defineStore(
  "user",
  () => {
    const id = ref(null)
    const username = ref(null)
    const email = ref(null)
    const siteAccessList = ref([])
    const token = ref(null)
    const status = ref(null)
    const expired = ref(false)
    const trial = ref(false)
    const offeredTrialUpgrade = ref(false)
    const offeredReactivation = ref(false)
    const active = ref(false)
    const accessLevel = ref(null)
    const permissions = ref([])
    const canStream = ref(false)
    const canDownload = ref(false)
    const biller = ref(null)
    const joinedAt = ref(null)
    const loggedIn = ref(false)
    const siteId = ref(null)
    const siteName = ref(null)
    const siteShortName = ref(null)
    const ageConfirmed = ref(false)
    const camUrls = ref({})
    const trailerPlayCount = ref(0)
    const billerJoinEpochDismissed = ref(false)
    const saleSpecialDismissedDate = ref(null)
    const isSaleSpecialDismissed = ref(true)
    const redirectUrl = ref(null)
    const dismissalDate = new Date(saleSpecialDismissedDate.value)
    const currentDate = new Date()
    const reactions = ref([])
    const favoritesCount = ref(null)
    const notificationsCount = ref(null)
    const subscriptionsCount = ref(null)

    if (currentDate > dismissalDate) {
      clearSaleSpecialDismissal()
      isSaleSpecialDismissed.value = false
    } else {
      isSaleSpecialDismissed.value = true
    }

    const signedLinkToCamSite = computed(() => {
      if (camUrls.value?.streammate_urls?.root) {
        return camUrls.value.streammate_urls.root
      } else {
        return null
      }
    })

    const confirmAge = () => {
      ageConfirmed.value = true
    }

    const offerTrialUpgrade = () => {
      offeredTrialUpgrade.value = true
    }

    const offerReactivation = () => {
      offeredReactivation.value = true
    }

    const loadCounts = async () => {
      if (favoritesCount.value === null) {
        const [
          { data: favorites },
          { data: notifications },
          { data: subscriptions },
        ] = await Promise.all([
          useApi("/api/members/favorites?count=true"),
          useApi("/api/members/notifications?count=true"),
          useApi("/api/members/subscriptions?count=true"),
        ])

        favoritesCount.value = favorites.value.pagination.totalItems
        notificationsCount.value = notifications.value.pagination.totalItems
        subscriptionsCount.value = subscriptions.value.count
      }
    }

    const loadFavoritesCount = async () => {
      const { $api } = useNuxtApp()
      const response = await $api("/api/members/favorites?count=true")
      favoritesCount.value = response.pagination.totalItems
    }

    const loadNotificationsCount = async () => {
      const { $api } = useNuxtApp()
      const response = await $api("/api/members/notifications?count=true")
      notificationsCount.value = response.pagination.totalItems
    }

    const loadSubscriptionsCount = async () => {
      const { $api } = useNuxtApp()
      const response = await $api("/api/members/subscriptions?count=true")
      subscriptionsCount.value = response.count
    }

    const loadUser = (data: any, setCookie = true) => {
      id.value = data.member.id
      username.value = data.member.username
      email.value = data.member.email
      status.value = data.member.status
      accessLevel.value = data.member.accessLevel
      trial.value = data.member.accessLevel === "trial"
      expired.value = data.member.accessLevel === "expired"
      active.value = data.member.accessLevel === "active"
      permissions.value = data.member.permissions
      canStream.value = data.member.canStream
      canDownload.value = data.member.canDownload
      biller.value = data.member.biller
      joinedAt.value = data.member.joinedAt
      siteId.value = data.member.site_id
      siteAccessList.value = data.member.siteAccessList
      siteName.value = data.member.siteName
      siteShortName.value = data.member.siteShortName
      ageConfirmed.value = true
      loggedIn.value = true
      reactions.value = data.reactions
      camUrls.value = data.cam_urls
      trailerPlayCount.value = 0
      redirectUrl.value = data.redirect_url

      if (setCookie) {
        const cookie = useCookie("i", {
          path: "/",
          maxAge: 60 * 60 * 24 * 7, // 7 days expiration
          // httpOnly: true, // Important for security
          secure: process.env.NODE_ENV === "production", // Only set 'Secure' in production
        })
        cookie.value = data.member.accessToken
      }
    }

    async function login(provided_username: string, provided_password: string) {
      try {
        const headers = useRequestHeaders(["host"])
        const host = import.meta.server ? headers?.host : window.location.host
        const response = await fetch(`/api/session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Site": host,
          },
          body: JSON.stringify({
            username: provided_username,
            password: provided_password,
          }),
        })

        if (response.ok) {
          loadUser(await response.json())
        }
      } catch (error) {
        console.error("Login failed:", error.message)
        this.$reset()
      }
    }

    async function loginWithToken(t: string) {
      try {
        const headers = useRequestHeaders(["host"])
        const host = import.meta.server ? headers?.host : window.location.host
        const response = await fetch(`/api/session/token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Site": host,
          },
          body: JSON.stringify({
            token: t,
          }),
        })

        if (response.ok) {
          loadUser(await response.json())
        } else {
          return { error: "Invalid token" }
        }
      } catch (error) {
        console.error("Login failed:", error.message)
        this.$reset()
      }
    }

    async function refetchUser() {
      try {
        const headers = useRequestHeaders(["host"])
        const host = import.meta.server ? headers?.host : window.location.host
        const response = await fetch(`/api/members/${id.value}`, {
          headers: {
            "Content-Type": "application/json",
            "X-Site": host,
            Cookie: `i=${token.value}`,
          },
        })

        if (response.ok) {
          loadUser(await response.json())
        } else {
          return { error: "Invalid token" }
        }
      } catch (error) {
        console.error("Login failed:", error.message)
        this.$reset()
      }
    }

    async function storeUserFromGuestAccess(data: any) {
      loadUser(data)
      return data
    }

    function incrementTrailerPlayCount() {
      trailerPlayCount.value++
    }

    function dismissBillerJoinEpoch() {
      billerJoinEpochDismissed.value = true
    }

    function dismissSaleSpecial(daysToExpire = 30) {
      const expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + daysToExpire)
      saleSpecialDismissedDate.value = expiryDate.toISOString()
      isSaleSpecialDismissed.value = true
    }

    function isAllowedToInteract() {
      return expired.value === false && trial.value === false
    }

    function clearSaleSpecialDismissal() {
      saleSpecialDismissedDate.value = null
      isSaleSpecialDismissed.value = false
    }

    function $reset() {
      id.value = null
      username.value = null
      email.value = null
      status.value = null
      accessLevel.value = null
      trial.value = false
      expired.value = false
      active.value = false
      permissions.value = []
      canStream.value = false
      canDownload.value = false
      biller.value = null
      joinedAt.value = null
      siteId.value = null
      siteAccessList.value = []
      siteName.value = null
      siteShortName.value = null
      loggedIn.value = false
      reactions.value = []
      camUrls.value = {}
      trailerPlayCount.value = 0
      redirectUrl.value = null
      favoritesCount.value = null
      notificationsCount.value = null
      subscriptionsCount.value = null

      const cookie = useCookie("i")
      cookie.value = null
    }

    return {
      $reset,
      accessLevel,
      active,
      ageConfirmed,
      biller,
      billerJoinEpochDismissed,
      canDownload,
      canStream,
      confirmAge,
      dismissBillerJoinEpoch,
      dismissSaleSpecial,
      expired,
      favoritesCount,
      id,
      incrementTrailerPlayCount,
      isSaleSpecialDismissed,
      joinedAt,
      loadCounts,
      loadFavoritesCount,
      loadNotificationsCount,
      loadSubscriptionsCount,
      loggedIn,
      login,
      loginWithToken,
      notificationsCount,
      offerReactivation,
      offerTrialUpgrade,
      offeredTrialUpgrade,
      permissions,
      refetchUser,
      siteAccessList,
      siteId,
      siteName,
      siteShortName,
      subscriptionsCount,
      status,
      storeUserFromGuestAccess,
      token,
      trailerPlayCount,
      trial,
      username,
      email,
      camUrls,
      signedLinkToCamSite,
      redirectUrl,
      isAllowedToInteract,
      reactions,
    }
  },
  {
    persist: true,
  },
)
