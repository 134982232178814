export default defineAppConfig({
  ui: {
    primary: "stone",
    cool: "gray",
    button: {
      color: {
        primary: {
          solid:
            "ring-1 ring-black text-gray-900 font-bold leading-[13px] bg-gradient-to-b from-[#272727] to-[#191919] hover:from-[#191919] hover:to-[#272727] rounded-[5px] justify-center items-center inline-flex text-white",
          soft: "ring-0 bg-[#272727] rounded-[5px] border border-[#1a1a1a] flex-col justify-center items-center gap-2.5 inline-flex text-white",
        },
        white: {
          solid:
            "ring-0 border border-black text-gray-900 font-bold leading-[13px] bg-gradient-to-b from-[#272727] to-[#191919] hover:from-[#191919] hover:to-[#272727] rounded-[5px] justify-center items-center inline-flex text-white",
          soft: "ring-0 bg-[#272727] rounded-[5px] border border-[#1a1a1a] flex-col justify-center items-center gap-2.5 inline-flex text-white",
        },
        green: {
          solid:
            "font-bold leading-[13px] bg-gradient-to-b from-[#29f863] to-[#1ebe4b] rounded-[5px] ring ring-[#111111] flex-col justify-center items-center gap-2.5 inline-flex text-white text-shadow",
        },
        blue: {
          solid:
            "font-bold leading-[13px] bg-[#0891f5] rounded-[5px] ring ring-[#111111] flex-col justify-center items-center gap-2.5 inline-flex !text-black text-shadow shadow-[1px_1px_0px_0px_rgba(0,0,0,0.70)]",
        },
      },
    },
    select: {
      color: {
        white: {
          outline:
            "bg-[#1A1A1A] dark:bg-[#1A1A1A] text-white dark:text-white ring-0 bg-gradient-to-b from-[#272727] to-[#191919] ring-0 border border-black text-white dark:text-white",
        },
      },
    },
    notification: {
      title: "text-base",
    },
    notifications: {
      position: "top-0 bottom-auto",
    },
    modal: {
      container: "!items-center",
    },
  },
})
