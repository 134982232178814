import { default as _2257LLwj0LAtDdMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/2257.vue?macro=true";
import { default as content_removalrs9pbZPkK2Meta } from "/home/runner/work/pornplus/pornplus/frontend/pages/content_removal.vue?macro=true";
import { default as dmca8weB2gGfxNMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/dmca.vue?macro=true";
import { default as indexJesDORas5yMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/index.vue?macro=true";
import { default as autom8xp0SAEbdMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/login/auto.vue?macro=true";
import { default as indexIBzCu0jyrQMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/login/index.vue?macro=true";
import { default as indexXBHXOkl2PcMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/account/index.vue?macro=true";
import { default as indexaxT0uJwh0tMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/categories/index.vue?macro=true";
import { default as index9mX4oyPXF7Meta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/deals/index.vue?macro=true";
import { default as indextiTW8pUxNWMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/favorites/index.vue?macro=true";
import { default as index5bfEzkO6KRMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/index.vue?macro=true";
import { default as indextWPptaz4bjMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/membership/index.vue?macro=true";
import { default as _91slug_93e5StwtizEfMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/models/[slug].vue?macro=true";
import { default as index9CXUR76tRvMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/models/index.vue?macro=true";
import { default as index6VzBSmtCk8Meta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/movies/index.vue?macro=true";
import { default as indexr8RVYFfWJCMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/notifications/index.vue?macro=true";
import { default as indexqylgWzBdLLMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/offers/index.vue?macro=true";
import { default as _91slug_93Wl5UQ5fxlDMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/series/[slug].vue?macro=true";
import { default as indexB1HSpBDfE5Meta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/series/index.vue?macro=true";
import { default as index8i0ycBwW4VMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/subscriptions/index.vue?macro=true";
import { default as indexHpyhBVxquaMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/upgrade/index.vue?macro=true";
import { default as _91_91section_93_93Ca63NtwnpJMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/video/[slug]/[[section]].vue?macro=true";
import { default as indexaG3dWaiQixMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/members/video/[slug]/pictures/index.vue?macro=true";
import { default as _91slug_93zDmIgiVb7rMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/models/[slug].vue?macro=true";
import { default as indexUBAi11QjCmMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/models/index.vue?macro=true";
import { default as privacyROmVaCm0DqMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/privacy.vue?macro=true";
import { default as _91slug_93BjBDE9FlaEMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/series/[slug].vue?macro=true";
import { default as indexc8OynBn1VgMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/series/index.vue?macro=true";
import { default as _91slug_93H3ylAuIe14Meta } from "/home/runner/work/pornplus/pornplus/frontend/pages/sites/[slug].vue?macro=true";
import { default as index8IVqxOw8XgMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/sites/index.vue?macro=true";
import { default as termsiwfrAM8CeyMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/terms.vue?macro=true";
import { default as _91id_932PG0aI99QsMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/tour/image/images/[siteName]/[setName]/[imageName]/[id].vue?macro=true";
import { default as top_45rated34I0WSzQ0uMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/tours/top-rated.vue?macro=true";
import { default as _91slug_93F5OYrPUukNMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/video/[slug].vue?macro=true";
import { default as indexKjDv4sa0fBMeta } from "/home/runner/work/pornplus/pornplus/frontend/pages/welcome/index.vue?macro=true";
import { default as component_45stubhwQ8m2ybPmMeta } from "/home/runner/work/pornplus/pornplus/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubhwQ8m2ybPm } from "/home/runner/work/pornplus/pornplus/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "2257",
    path: "/2257",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/2257.vue")
  },
  {
    name: "content_removal",
    path: "/content_removal",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/content_removal.vue")
  },
  {
    name: "dmca",
    path: "/dmca",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/dmca.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/index.vue")
  },
  {
    name: "login-auto",
    path: "/login/auto",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/login/auto.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/login/index.vue")
  },
  {
    name: "members-account",
    path: "/members/account",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/account/index.vue")
  },
  {
    name: "members-categories",
    path: "/members/categories",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/categories/index.vue")
  },
  {
    name: "members-deals",
    path: "/members/deals",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/deals/index.vue")
  },
  {
    name: "members-favorites",
    path: "/members/favorites",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/favorites/index.vue")
  },
  {
    name: "members",
    path: "/members",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/index.vue")
  },
  {
    name: "members-membership",
    path: "/members/membership",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/membership/index.vue")
  },
  {
    name: "members-models-slug",
    path: "/members/models/:slug()",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/models/[slug].vue")
  },
  {
    name: "members-models",
    path: "/members/models",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/models/index.vue")
  },
  {
    name: "members-movies",
    path: "/members/movies",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/movies/index.vue")
  },
  {
    name: "members-notifications",
    path: "/members/notifications",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/notifications/index.vue")
  },
  {
    name: "members-offers",
    path: "/members/offers",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/offers/index.vue")
  },
  {
    name: "members-series-slug",
    path: "/members/series/:slug()",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/series/[slug].vue")
  },
  {
    name: "members-series",
    path: "/members/series",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/series/index.vue")
  },
  {
    name: "members-subscriptions",
    path: "/members/subscriptions",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/subscriptions/index.vue")
  },
  {
    name: "members-upgrade",
    path: "/members/upgrade",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/upgrade/index.vue")
  },
  {
    name: "members-video-slug-section",
    path: "/members/video/:slug()/:section?",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/video/[slug]/[[section]].vue")
  },
  {
    name: "members-video-slug-pictures",
    path: "/members/video/:slug()/pictures",
    meta: {"middleware":["auth"]},
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/members/video/[slug]/pictures/index.vue")
  },
  {
    name: "models-slug",
    path: "/models/:slug()",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/models/[slug].vue")
  },
  {
    name: "models",
    path: "/models",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/models/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/privacy.vue")
  },
  {
    name: "series-slug",
    path: "/series/:slug()",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/series/[slug].vue")
  },
  {
    name: "series",
    path: "/series",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/series/index.vue")
  },
  {
    name: "sites-slug",
    path: "/sites/:slug()",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/sites/[slug].vue")
  },
  {
    name: "sites",
    path: "/sites",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/sites/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/terms.vue")
  },
  {
    name: "tour-image-images-siteName-setName-imageName-id",
    path: "/tour/image/images/:siteName()/:setName()/:imageName()/:id()",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/tour/image/images/[siteName]/[setName]/[imageName]/[id].vue")
  },
  {
    name: "tours-top-rated",
    path: "/tours/top-rated",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/tours/top-rated.vue")
  },
  {
    name: "video-slug",
    path: "/video/:slug()",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/video/[slug].vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/home/runner/work/pornplus/pornplus/frontend/pages/welcome/index.vue")
  },
  {
    name: component_45stubhwQ8m2ybPmMeta?.name,
    path: "/girls",
    component: component_45stubhwQ8m2ybPm
  },
  {
    name: component_45stubhwQ8m2ybPmMeta?.name,
    path: "/guys",
    component: component_45stubhwQ8m2ybPm
  }
]